import { graphql } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import React from 'react'
import { Breadcrumb, BreadcrumbItem } from '../components/Breadcrumb'
import { ItemContainer, ItemGrid } from '../components/ItemGrid'
import { WideBannerWithCaptionBlock } from '../components/modular-blocks/WideBannerWithCaption.block'
import { WidthWrapper } from '../components/WidthWrapper'
import {
  BrandTemplateQuery,
  BrandTemplateQueryVariables,
} from '../types/graphql'
import { resolvePath } from '../utils/dato'

export type PageContext = BrandTemplateQueryVariables

type Props = {
  data: BrandTemplateQuery
}

function BrandTemplate({ data }: Props) {
  const {
    seoMetaTags,
    name,
    slug,
    brandColor,
    logo,
    content,
    brandSubcategoryEntry,
  } = data.datoCmsBrand!

  return (
    <>
      <HelmetDatoCms seo={seoMetaTags} />

      <main>
        <Breadcrumb>
          <BreadcrumbItem href="/products/">Products</BreadcrumbItem>
          <BreadcrumbItem>{name}</BreadcrumbItem>
        </Breadcrumb>

        <WidthWrapper className="mt-16">
          <div className="flex items-center">
            <img
              src={logo!.url!}
              alt={`${name} logo`}
              className="h-12 w-24 object-contain"
            />

            <h1 className="text-3xl text-accent-800 ml-4 font-bold">{name}</h1>
          </div>
        </WidthWrapper>

        {content && content.length > 0
          ? content.map(block => {
              if (!block) return null

              switch (block.__typename) {
                case 'DatoCmsWideBannerWithCaption':
                  return <WideBannerWithCaptionBlock data={block} />
                default:
                  const _exhaustiveCheck: never = block
                  return _exhaustiveCheck
              }
            })
          : null}

        <WidthWrapper className="mt-16">
          <h2 className="mb-16 text-3xl text-center text-accent-800 font-bold">
            Categories
          </h2>

          <ItemGrid>
            {brandSubcategoryEntry &&
            brandSubcategoryEntry.treeChildren &&
            brandSubcategoryEntry.treeChildren.length > 0
              ? brandSubcategoryEntry.treeChildren.map(brandSubcategory => {
                  if (
                    !slug ||
                    !brandColor ||
                    !brandColor.hex ||
                    !brandSubcategory ||
                    !brandSubcategory.name ||
                    !brandSubcategory.slug ||
                    !brandSubcategory.image ||
                    !brandSubcategory.image.fluid
                  ) {
                    return null
                  }

                  return (
                    <ItemContainer
                      linkTo={resolvePath({
                        kind: 'brandSubcategory',
                        slug: brandSubcategory.slug,
                        brandSlug: slug,
                      })}
                      name={brandSubcategory.name}
                      image={
                        <Img
                          fluid={brandSubcategory.image.fluid as FluidObject}
                          alt=""
                        />
                      }
                    />
                  )
                })
              : null}
          </ItemGrid>
        </WidthWrapper>
      </main>
    </>
  )
}

export const query = graphql`
  query BrandTemplateQuery($slug: String!) {
    datoCmsBrand(slug: { eq: $slug }) {
      seoMetaTags {
        tags
      }

      name
      slug
      brandColor {
        hex
      }
      logo {
        url
      }

      content {
        __typename

        ... on DatoCmsWideBannerWithCaption {
          ...WideBannerWithCaptionFragment
        }
      }

      brandSubcategoryEntry {
        treeChildren {
          name
          slug
          image {
            fluid(
              maxWidth: 300
              maxHeight: 300
              imgixParams: { bg: "0FFF", w: "300", h: "300", fit: "fillmax" }
            ) {
              ...DatoFluid
            }
          }
        }
      }
    }
  }
`

export default BrandTemplate
